<template>
    <v-sheet>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="" cols="2">               
                <v-text-field
                    v-model="srchTxt" @input="setSearchText"
                    class="txt-Theme" height="34" label="제목 내용 조회"
                    background-color="white" prepend-inner-icon="mdi-magnify"
                    single-line outlined dense filled hide-details>
                </v-text-field>
                <v-spacer></v-spacer>
            </v-col>
        </v-row>
        <v-row class="mt-3 pa-0" no-gutters>
            <v-col class="" cols="12">
                <v-data-table
                    class="dft-hvr-tbl" locale="ko" height="450"
                    :headers="headers" :items="yysNotices"
                    :loading="loader" 
                    loading-text="Loading... Please wait"
                    hide-default-header hide-default-footer dense disable-pagination>
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr class="">
                                <th class="px-2 py-2 ntcTbHeader" style="width: 8%;">
                                    <span class="black--text">{{ props.headers[0].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 67%;">
                                    <span class="black--text">{{ props.headers[1].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 15%;">
                                    <span class="black--text">{{ props.headers[2].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 10%; border-right: initial !important;">
                                    <span class="black--text">{{ props.headers[3].text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr :class="[sltRow.ntcBbsPk == item.ntcBbsPk ? 'onClr' : '']" @click="evtRow(item)">
                            <td 
                                class="hvrClr tableBR tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ item.rn }}</span>
                            </td>
                            <td 
                                class="hvrClr tableBR tableBB py-2">
                                <span class="fontOneRem">{{ item.title }}</span>
                            </td>
                            <td 
                                class="hvrClr tableBR tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ item.crtDt }}</span>
                            </td>
                            <td 
                                class="hvrClr tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ item.crtr }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="mt-2 pa-0" no-gutters>
            <v-col class="" cols="12">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    @input="getPage"
                    total-visible="10">
                </v-pagination>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1100">    
                <YysAllNoticeViw 
                    v-if="dialog[0]"             
                    @isClose="modalEvent(0)" 
                ></YysAllNoticeViw>                  
            </v-dialog>   
        </div>    
    </v-sheet>
</template>

<script>
import { selYysBbsList } from '../../api/othr.js';
import YysAllNoticeViw from './YysAllNoticeViw.vue';

export default {
    name: 'YysAllNotice',

    props : {
   
    },
        
    components: {
        YysAllNoticeViw
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selYysBbsList(0,this.itemsRows)
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        selYysBbsList: function (strt, lmt) {
            this.yysNotices.splice(0)
            this.loader = true
            let obj = {                
                rnStrt:strt,
                rnLmt:lmt, 
                srchWd1:this.srchTxt,
            }      

            selYysBbsList(obj)
                .then((response) => (this.selYysBbsListAfter(response.data)))
                .catch((error) => console.log('connect error othr/selYysBbsList : ' + error))
        },
        selYysBbsListAfter :function (res){
            console.log(res)
            this.loader = false
            let obj = {}    
            if(res.statusCode == 200){ 
                if(res.result.list !='' && res.result.list != null){  
                    this.pageCount = Math.ceil(Number(res.result.rnTot)/this.itemsRows)
                    res.result.list.forEach(el => {
                        this.yysNotices.push(el)
                    });

                }
            } else {
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.selYysBbsList(strt,this.itemsRows)
        },
        setSearchText:function(){
            let pattern = /([^가-힣a-z\x20])/i
            
            if(this.srchTxt != ''){
                if(!pattern.test(this.srchTxt)) {
                    this.page = 1
                    this.selYysBbsList(0,this.itemsRows)
                }
            } else {
                this.page = 1
                this.selYysBbsList(0,this.itemsRows)
            }
        },

        // 테이블 행 클릭 이벤트
        evtRow: function (obj){
            if(this.sltRow.ntcBbsPk == obj.ntcBbsPk){
                this.sltRow.ntcBbsPk = -1
                this.sltRow.title = '' 
                this.sltRow.cntnt = '' 
                this.sltRow.cntntHtml = ''
                this.sltRow.crtr = ''
                this.sltRow.crtDt = ''
            }
            else{
                this.sltRow.ntcBbsPk = obj.ntcBbsPk
                this.sltRow.title = obj.title 
                this.sltRow.cntnt = obj.cntnt 
                this.sltRow.cntntHtml = obj.cntntHtml
                this.sltRow.crtr = obj.crtr
                this.sltRow.crtDt = obj.crtDt
                this.dialog.splice(0, 1, true)
            }
        },

        modalEvent:function(key){
            this.dialog.splice(key, 1, false)
        }
    },

    data: () => ({
        dialog:[false,],
        page: 1,
        pageCount: 0,
        totCnt: 0,
        itemsRows: 10,
        sltYysBbsPk: -1,
        srchTxt: '',
        sltRow:{ntcBbsPk:-1, title:'', cntnt:'', cntntHtml:'',crtr:'',crtDt:'',},
        loader:false,        
        yysNotices: [],
        headers: [
            { text: '번호', value: 'rn', sortable: true, align: 'center', class: 'black--text' },
            { text: '제목', value: 'title', sortable: true, align: 'center', class: 'black--text' },
            { text: '등록일', value: 'crtDt', sortable: true, align: 'center', class: 'black--text' },
            { text: '작성자', value: 'crtr', sortable: true, align: 'center', class: 'black--text' },
        ],
    }),
};
</script>