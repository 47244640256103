<template>
    <v-sheet class="ma-0" rounded="lg">
        <span>
            <div id="overlay"></div>
            <div id="popup" style="width: 100%; height: 100%;">
                <iframe
                    id="payment-frame" name="payment-frame" scrolling="no" frameborder="0" allowtransparency="true"
                    style="width: 820px; height: 630px; background: #FFFFFF; border-radius: 20px;">
                </iframe>

            </div>
        </span>
        <form
            name="KSPayWeb" method="post" target="payment-frame"
            action="https://kspay.ksnet.to/store/KSPayWebV1.4/KSPayPWeb.jsp">
            <input type='hidden' name='sndStoreid' value=''>                <!-- 상점아이디 -->
            <input type='hidden' name='sndReply' value=''>                  <!-- 응답페이지 주소 -->
            <input type='hidden' name='sndCharSet' value='UTF-8'>           <!-- 가맹점 CharSet -->
            <input type='hidden' name='sndGoodType' value='2'>              <!-- 상품유형 -->
            <input type='hidden' name='iframeYn' value='Y'>                 <!-- 결제 창 호출 방식 -->
            <input type='hidden' name='sndPaymethod' value=''>              <!-- 결제수단 -->
            <input type='hidden' name='sndOrdernumber' value=''>            <!-- 주문번호 -->
            <input type='hidden' name='sndGoodname' value=''>               <!-- 상품명 -->
            <input type='hidden' name='sndAmount' value=''>                 <!-- 금액 -->
            <input type='hidden' name='sndOrdername' value=''>              <!-- 주문자명 -->
            <input type='hidden' name='sndShowcard' value='C'>              <!-- 신용카드 노출 옵션 -->
            <input type='hidden' name='sndCurrencytype' value='WON'>        <!-- 통화코드 -->
            <input type='hidden' name='sndInstallmenttype' value='ALL(0)'>  <!-- 할부개월 -->
            <input type='hidden' name='sndInteresttype' value='NONE'>       <!-- 가맹점부담 무이자 -->
            <input type='hidden' name='sndEmail' value=''>                  <!-- 이메일 -->
            <input type='hidden' name='sndMobile' value=''>                 <!-- 휴대전화 -->
            <input type='hidden' name='sndServicePeriod' value=''>          <!-- 용역 제공 기간 -->
            <input type='hidden' name='sndStoreCeoName' value=''>           <!-- 카카오 상점대표자명 -->
            <input type='hidden' name='sndStorePhoneNo' value=''>           <!-- 카카오 연락처 -->
            <input type='hidden' name='sndStoreAddress' value=''>           <!-- 카카오 주소 -->
            <input type='hidden' name='sndQpayType' value='1'>              <!-- 간편결제 표시 구분 -->
            <input type='hidden' name='sndCashReceipt' value=''>            <!-- 계좌이체 현금영수증 여부 -->
            <input type='hidden' name='reCommConId' value=''>               <!-- 승인응답 결과데이터 -->
            <input type='hidden' name='reCommType' value=''>                <!-- 승인응답 결과데이터 -->
            <input type='hidden' name='reHash' value=''>                    <!-- 승인응답 결과데이터 -->
        </form>
    </v-sheet>
</template>

<script>
import { http, remoteURL } from '@/api/baseAxios';

export default {
    name: 'KsnetPayments',

    components: {
   
    },

    props : {
        payMthd: { type: Number, default: -1 },     // 결제종류
    },

    created: function(){
        // 일회용 이벤트 생성
        window.addEventListener( 'message', (e) => {
            if(e.type == 'message'){
                switch ( e.data.status ) {
                    case 'success':
                        document.KSPayWeb.reCommConId.value = e.data.rcid
                        document.KSPayWeb.reCommType.value = e.data.rctype
                        document.KSPayWeb.reHash.value = e.data.rhash

                        this.rcvKsnet()
                        break;
                    case 'error':
                        this.onMsg(e.data.message, 'warning')
                        this.onClose()
                        break;
                    case 'cancel':
                        this.onClose()
                        break;
                    default:
                        break;
                }
            }
        }, { once: true });
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.callKsnet()
        })
    },

    computed: {
      
    },

    watch: {
        
    },

    methods:{
        // 결제 대기 상태에서 실제 결제 요청
        ksPayWhResult: function (obj){
            http.post('pym/kspaywhresult', obj)
                .then((response) => ( this.ksPayWhRst(response.data) ))
                .catch((error) => console.log('connect error /pym/kspaywhresult : ' + error))
        },

        ksPayWhRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 5000                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            switch (res.statusCode) {
                case '201':
                case '202':
                    this.$parent.$parent.$parent.$parent.savBtn = true
                    break;
                default:
                    obj.type = 'warning'
                    break;
            }

            this.$store.commit('setWrnnInf', obj)
            this.onClose()
        },

        // 케이에스넷 결제창 생성
        callKsnet: function (){
            switch (this.payMthd) {
                case 0:     // 무통장입금 (가상계좌)
                    document.KSPayWeb.sndPaymethod.value = '0100000000000'
                    document.KSPayWeb.sndStoreid.value = '2999199999'
                    break;
                case 1:     // 신용카드
                    document.KSPayWeb.sndPaymethod.value = '1000000000000'
                    document.KSPayWeb.sndStoreid.value = '2999199999'   // 테스트(2999199999), 실결제(2052200001)
                    break;
                case 2:     // 계좌이체
                    document.KSPayWeb.sndPaymethod.value = '0010000000000'
                    document.KSPayWeb.sndStoreid.value = '2052200002'
                    break;
                default:
                    break;
            }

            // 주문고유번호 생성
            let uuidV4 = crypto.randomUUID()
            let arr = uuidV4.split('-')
            let uuid = ''
            arr.forEach((itm, i) => {
                if(i > 0)
                    uuid += itm
            });

            // 주문 기본정보 세팅
            let emailFull = this.$parent.$parent.$parent.$parent.fcltyInf.emailId + '@' + this.$parent.$parent.$parent.$parent.fcltyInf.emailDmn
            let tel = this.$parent.$parent.$parent.$parent.fcltyInf.tel1 + this.$parent.$parent.$parent.$parent.fcltyInf.tel2 + this.$parent.$parent.$parent.$parent.fcltyInf.tel3
            let address = this.$parent.$parent.$parent.$parent.fcltyInf.addr + '' + this.$parent.$parent.$parent.$parent.fcltyInf.addrDtl
            let ordIdx = Number(this.$parent.$parent.$parent.$parent.sltPrd)
            if(ordIdx > -1)
                document.KSPayWeb.sndGoodname.value = this.$parent.$parent.$parent.$parent.prdctUseList[ordIdx-1].prdNm

            //document.KSPayWeb.sndAmount.value = this.$parent.$parent.$parent.$parent.bscsInf.useAmt
            document.KSPayWeb.sndAmount.value = 1000
            document.KSPayWeb.sndOrdername.value = this.$parent.$parent.$parent.$parent.fcltyInf.ceo
            document.KSPayWeb.sndOrdernumber.value = uuid
            document.KSPayWeb.sndReply.value = remoteURL + 'pay/kspaywhrcv'

            document.KSPayWeb.sndEmail.value = emailFull
            document.KSPayWeb.sndStoreCeoName.value = this.$parent.$parent.$parent.$parent.fcltyInf.ceo
            document.KSPayWeb.sndStorePhoneNo.value = tel
            document.KSPayWeb.sndStoreAddress.value = address
            if(this.$parent.$parent.$parent.$parent.bscsInf.taxInvcC == '2')
                document.KSPayWeb.sndCashReceipt.value = '1'
            
            // KSNET 결제창 호출
            document.KSPayWeb.submit()
        },

        // 결제창에서 데이터를 받아 최종결제 페이지에 전달
        rcvKsnet: function () {
            let ordIdx = Number(this.$parent.$parent.$parent.$parent.sltPrd)
            let obj = null

            // 결제종류에 따라 기관정보 베이스 설정
            if(this.payMthd == 2)
                obj = {}
            else
                obj = Object.assign({}, this.$parent.$parent.$parent.$parent.fcltyInf)

            // 결제 기초정보 설정 (상품코드, 서비스코드)
            if(ordIdx > -1){
                obj.pymtMnth = this.$parent.$parent.$parent.$parent.prdctUseList[ordIdx-1].useMnth
                obj.mjrPrdCd = this.$parent.$parent.$parent.$parent.prdctUseList[ordIdx-1].mjrPrdCd
            }

            obj.pymtMthdCd = (this.payMthd + 1)
            obj.srvcClcd = this.$parent.$parent.$parent.$parent.bscsInf.srvcClcd
            obj.bnfcrCnt = this.$parent.$parent.$parent.$parent.bscsInf.bnfcrCnt
            obj.yysUseLstPk = this.$parent.$parent.$parent.$parent.bscsInf.yysUseLstPk
            obj.srvcMode = this.$parent.$parent.$parent.$parent.bscsInf.srvcMode
            // textCnt  --문자건수

            // 세금계산서, 현금영수증 기초 설정 ( 신용카드가 아닐경우 )
            if(this.payMthd != '2')
                obj.taxInvcCd = this.$parent.$parent.$parent.$parent.bscsInf.taxInvcCd

            // KSNET 결체요청 정보 전달
            obj.sndStoreid = document.KSPayWeb.sndStoreid.value
            obj.sndGoodType = document.KSPayWeb.sndGoodType.value
            obj.iframeYn = document.KSPayWeb.iframeYn.value
            obj.sndPaymethod = document.KSPayWeb.sndPaymethod.value
            obj.sndOrdernumber = document.KSPayWeb.sndOrdernumber.value
            obj.sndGoodname = document.KSPayWeb.sndGoodname.value
            obj.sndAmount = document.KSPayWeb.sndAmount.value
            obj.sndOrdername = document.KSPayWeb.sndOrdername.value
            obj.sndShowcard = document.KSPayWeb.sndShowcard.value
            obj.sndCurrencytype = document.KSPayWeb.sndCurrencytype.value
            obj.sndInstallmenttype = document.KSPayWeb.sndInstallmenttype.value
            obj.sndInteresttype = document.KSPayWeb.sndInteresttype.value
            obj.sndEmail = document.KSPayWeb.sndEmail.value
            obj.sndMobile = document.KSPayWeb.sndMobile.value
            obj.sndServicePeriod = document.KSPayWeb.sndServicePeriod.value
            obj.sndStoreCeoName = document.KSPayWeb.sndStoreCeoName.value
            obj.sndStorePhoneNo = document.KSPayWeb.sndStorePhoneNo.value
            obj.sndStoreAddress = document.KSPayWeb.sndStoreAddress.value
            obj.sndQpayType = document.KSPayWeb.sndQpayType.value
            obj.sndCashReceipt = document.KSPayWeb.sndCashReceipt.value
            obj.reCommConId = document.KSPayWeb.reCommConId.value
            obj.reCommType = document.KSPayWeb.reCommType.value
            obj.reHash = document.KSPayWeb.reHash.value

            //console.log(obj)
            this.ksPayWhResult(obj)
        },

        onMsg: function (msg, typ) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 5000                              // 경고 노출시간 (-1: 무제한)
            obj.type = typ                              // 경고 유형 (success, info, warning, error)
            obj.title = msg                             // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            this.$store.commit('setWrnnInf', obj)
        },

        onClose: function () {
            //닫기 버튼 클릭시 발생 
            this.$emit('isOff', '')
        },
    },

    data: () => ({

    })
}
</script>